<template>
  <div class="about">
    <h1>About</h1>
    <!-- <img src="../assets/about-anna01.png" align="right" alt="Anna Tsatsura" /> -->
    <div class="content">
      <h2>👋🏻 Welcome to Positive Health Institute!</h2>
      <img src="../assets/anna02.jpg" alt="Anna Tsatsura" align="right"/>
      <p>
        Welcome to Positive Health Institute! I’m Anna, a passionate integrative
        health practitioner, personal trainer, & reiki practitioner. My road to
        becoming an alternative healthcare practitioner was paved by personal
        struggles with obesity, autoimmune disease, fibromyalgia, PTSD, anxiety,
        & depression. There was a point in my life at which I could not imagine
        going another day with the physical & emotional pain that burdened me. I
        had sought out many doctors and naturopaths and paid for many lab tests,
        but was always left without answers or effective treatment. It was not
        until I found integrative healthcare that I finally reversed my symptoms
        and sent my disease(s) into remission. Integrative healthcare, which
        addresses the true compounding root causes to illness, not only gave me
        my life back, but gave me a greater quality-of-life than before I became
        sick! It is my mission to provide my clients the guidance they need to
        bring them to a state of true optimal health.
      </p>      
      <p>
        As a personal trainer with almost a decade of experience I offer
        exclusive bio-individual one-on-one training that will take your fitness
        & physique to the next level. My technique as a trainer involves
        something I like to call mindfulness training that will teach you to
        engage your muscles in a brand new way garnering incredible results.
        This training will safely address your muscle imbalances, posture, deep
        core muscles, and will complement root-cause health protocols perfectly.
      </p>
      <p>
        I am also a certified Reiki practitioner which allows me to address my
        client’s emotional and energetic well-being. Emotional trauma and stress
        can greatly contribute to weight gain, chronic illness, fatigue, & more.
        Reiki and sound therapies allow me to provide the support that my
        clients need to succeed.
      </p>
      <h2>What is an Integrative Health Practitioner?</h2>
      <p>
        Integrative Health Practitioners are alternative & complementary health
        practitioners that use an effective combination the following modalities
        to help their clients address the root causes to their symptoms:
      </p>
      <img class="ihp-diagram" src="../assets/ihp-diagram.png" alt="ihp diagram" />
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {},
};
</script>

<style scoped>
h1 {
  background: var(--main-gradient);
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 40px;
}

h2,
p {
  margin-bottom: 20px;
}

img {
  width: 100%;
  max-width: 300px;
  padding: 0px 0px 30px 30px;
  margin: 0 auto;
  display: block;
}

@media (max-width:600px) {
  img  {
    padding:0px;
    max-width:100%;
    margin-bottom:30px;
  }
}

.ihp-diagram {
  max-width:600px;
  padding:0;
}

.content {
  margin-bottom: 100px;
  border: 3px solid var(--color-purple);
  border-radius: 20px;
  padding: 40px;
}
</style>
